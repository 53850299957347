import { swup } from '@/lib/swup'
import { Block, type BlockContext } from './block'
import type { Component } from 'vue'
import { createEventHook, type EventHookOn } from '@vueuse/core'

interface BlockModuleOptions {
    selector: string
    vueComponent?: Component
    setupBlock?: (el: Element, onTeardown: EventHookOn, context: BlockContext) => void
    inViewDebugMode?: boolean
}

export class BlockModule {
    elements: Element[] = []

    constructor(private options: BlockModuleOptions) {
        this.init()
        swup.hooks.on('page:view', () => this.init())
        window.addEventListener('blocks:reinit', () => this.init())
    }

    init() {
        this.elements = Array.from(document.querySelectorAll(this.options.selector))

        this.setupBlockControllers()
    }

    setupBlockControllers() {
        this.elements.forEach((el) => {
            if (el.classList.contains('block-initialized')) return

            const isNoTeardown = !!el.getAttribute('data-no-teardown')

            const ctrl = new Block(el, this.options.vueComponent, this.options.inViewDebugMode)

            const teardownHook = createEventHook()

            ctrl.init()
            
            if (this.options.setupBlock) {
                this.options.setupBlock(el, teardownHook.on, ctrl.context)
            }

            if (!isNoTeardown) return

            swup.hooks.on('animation:out:start', (e) => {
                teardownHook.trigger()
                ctrl.teardown()
                el.classList.remove('block-initialized')
            })
        })
    }
}
