import { defineComponent, defineAsyncComponent, ref, reactive, toRef, watch, computed } from "vue";
import { useBreakpoints, useUrlSearchParams } from "@vueuse/core";
import { tailwindBreakpoints } from "../tailwind";
import { BlockModule } from "../common";
import { 
    RadioGroupIndicator, 
    RadioGroupItem, 
    RadioGroupRoot
} from "radix-vue"
import { useProductListingAsync } from "@/datasources";

new BlockModule({
    selector: '.block-product-listing',
    vueComponent: defineComponent({
        components: {
            RadioGroupRoot,
            RadioGroupItem,
            RadioGroupIndicator,
            LazyImage: defineAsyncComponent(
                () => import('@/components/website/lazy-image/lazy-image.vue'),
            ),
            ToggleGroupRoot: defineAsyncComponent(
                () => import('radix-vue').then(x => x.ToggleGroupRoot),
            ),
            ToggleGroupItem: defineAsyncComponent(
                () => import('radix-vue').then(x => x.ToggleGroupItem),
            ),
            Drawer: defineAsyncComponent(() => import('@/components/ui/drawer/Drawer.vue')),
            DrawerClose: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogClose.vue'),
            ),
            DrawerContent: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerContent.vue'),
            ),
            DrawerDescription: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerDescription.vue'),
            ),
            DrawerFooter: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerFooter.vue'),
            ),
            DrawerTitle: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerTitle.vue'),
            ),
            DrawerTrigger: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTrigger.vue'),
            ),
            CheckboxControl: defineAsyncComponent(
                () => import('@/components/website/forms/checkbox-control/CheckboxControl.vue'),
            ),
            AutocompleteControl: defineAsyncComponent(
                () => import('@/components/website/forms/autocomplete-control/AutocompleteControl.vue')
            )
        },
        props: {
            groupKey: { type: String, required: true },
            defaultSort: String,
        },
        setup(props) {
            const isBusy = ref(true)

            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const xlAndGreater = breakpoints.greater('xl')

            const params = useUrlSearchParams('history')

            const filters = reactive({
                groupKey: props.groupKey,
                categoryTags: params.productCategory ? [params.productCategory as string] : ['All'],
                classificationTags: [] as string[]
            })

            const categoryTags = computed(() => filters.categoryTags.filter(x => x !== 'All'))
            const isOptionAllDisabled = computed(() => filters.categoryTags.length === 1 && filters.categoryTags.includes('All'))

            const productListingAsync = useProductListingAsync(
                toRef(filters, 'groupKey'),
                categoryTags,
                toRef(filters, 'classificationTags'),
                { evaluating: isBusy }
            )

            watch(
                () => filters.groupKey,
                () => {
                    filters.categoryTags = ['All']
                    filters.classificationTags = []
                }
            )

            watch(
                () => filters.categoryTags,
                (newCategoryTags) => {
                    if (newCategoryTags.length > 1 && newCategoryTags.findIndex(x => x === 'All') === newCategoryTags.length - 1) {
                        filters.categoryTags = ['All']
                    }  else if (newCategoryTags.length > 1 && newCategoryTags.includes('All')) {
                        filters.categoryTags = newCategoryTags.filter(x => x !== 'All')
                    } else if (newCategoryTags.length === 0) {
                        filters.categoryTags = ['All']
                    }
                }
            )

            const clearFilters = () => {
                filters.groupKey = props.groupKey
                filters.categoryTags = ['All']
                filters.classificationTags = []
            }

            return {
                isBusy,
                productListingAsync,
                filters,
                // onAllClick,
                isOptionAllDisabled,
                clearFilters,
                xlAndGreater
            }
        }
    })
})