import axios from 'axios'
// import qs from 'qs'

export const configureAxios = () => {
    axios.defaults.formSerializer = {
        indexes: false,
    }
    // axios.defaults.paramsSerializer = (params) => {
    //     return qs.stringify(params, {
    //         arrayFormat: 'indices',
    //         // indices: true,
    //     })
    // }
    axios.defaults.paramsSerializer = {
        indexes: true
    }
}
