import { defineComponent, defineAsyncComponent } from "vue"
import { useBreakpoints } from "@vueuse/core"
import { tailwindBreakpoints } from "../tailwind"
import { BlockModule } from "../common"

new BlockModule({
    selector: '.block-navbar-tabs',
    vueComponent: defineComponent({
        components: {
            DropdownMenu: defineAsyncComponent(
                () => import('@/components/ui/dropdown-menu/DropdownMenu.vue')
            ),
            DropdownMenuContent: defineAsyncComponent(
                () => import('@/components/ui/dropdown-menu/DropdownMenuContent.vue')
            ),
            DropdownMenuItem: defineAsyncComponent(
                () => import('@/components/ui/dropdown-menu/DropdownMenuItem.vue')
            ),
            DropdownMenuTrigger: defineAsyncComponent(
                () => import('@/components/ui/dropdown-menu/DropdownMenuTrigger.vue')
            ),
        },
        setup() {
            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const xlAndGreater = breakpoints.greater('xl')

            return {
                xlAndGreater
            }
        }
    })
})