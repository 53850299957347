import { useScroll } from "@vueuse/core"
import { ref, computed, watch, watchEffect } from "vue"

export const useNavContext = () => {
    const isMobileNavOpen = ref(false)
    const isGlobalSearchOpen = ref(false)
    const desktopFirstLevelActiveKey = ref('')
    const desktopSecondLevelActiveKey = ref('')
    const desktopThirdLevelActiveKey = ref('')
    const mobileFirstLevelActiveKey = ref('')
    const mobileSecondLevelActiveKey = ref('')
    const mobileIntermediariesActiveKey = ref('')

    const closeDesktopNav = () => desktopFirstLevelActiveKey.value = ''
    const closeMobileNavDialog = () => isMobileNavOpen.value = false
    const hideDesktopSecondLevelNav = () => desktopSecondLevelActiveKey.value = '' 
    const hideDesktopThirdLevelNav = () => desktopThirdLevelActiveKey.value = ''

    const { arrivedState, y } = useScroll(window)
    const isScrollingUpLazy = ref(false)

    // const globalAnnouncementAndMainNav = document.querySelector('.block-global-announcement-and-main-nav') as HTMLElement
    const globalAnnouncementAndMainNavRef = ref<HTMLElement>() 

    // console.log('globalAnnouncementAndMainNav', globalAnnouncementAndMainNav)

    const isNavHidden = computed(() => {
        if (arrivedState.top) {
            return false
        }
        if (isScrollingUpLazy.value) {
            return false
        }
        if (globalAnnouncementAndMainNavRef.value && y.value <= globalAnnouncementAndMainNavRef.value.offsetHeight) {
            return false
        }

        return true
    })

    // The directions in the `useScroll` composable resets when the user stops scrolling
    // We need to keep track of the scroll direction
    watch(y, (newY, oldY) => {
        if (newY < oldY) {
            isScrollingUpLazy.value = true
        }

        if (newY > oldY) {
            isScrollingUpLazy.value = false
        }
    })

    watchEffect(() => {
        if (isMobileNavOpen.value || isGlobalSearchOpen.value || desktopFirstLevelActiveKey.value) {
            document.body.classList.add('overflow-hidden')
        } else {
            document.body.classList.remove('overflow-hidden')
        }
        // if (defaultDesktopSecondLevelActiveKey.value) {
        //     desktopSecondLevelActiveKey.value = defaultDesktopSecondLevelActiveKey.value
        // }

        // if (defaultDesktopThirdLevelActiveKey.value) {
        //     desktopThirdLevelActiveKey.value = defaultDesktopThirdLevelActiveKey.value
        // }
    })

    return { 
        globalAnnouncementAndMainNavRef,
        isNavHidden,
        isMobileNavOpen,
        isGlobalSearchOpen,
        desktopFirstLevelActiveKey,
        desktopSecondLevelActiveKey,
        desktopThirdLevelActiveKey,
        mobileFirstLevelActiveKey,
        mobileSecondLevelActiveKey,
        mobileIntermediariesActiveKey,
        closeDesktopNav,
        closeMobileNavDialog,
        hideDesktopSecondLevelNav,
        hideDesktopThirdLevelNav
    }
}