import { BlockModule } from './common'
import { addDotBtnsAndClickHandlers, addPrevNextBtnsClickHandlers } from './embla' 

new BlockModule({
    selector: '.block-hero-carousel-banner',
    async setupBlock(el, onTeardown, context) {
        const emblaContainer = el.querySelector('.embla')

        if (!emblaContainer) return
        
        const dotsNode = <HTMLElement>emblaContainer?.querySelector('.embla__dots')
        const btnPrev = <HTMLElement>emblaContainer?.querySelector('.embla__button--prev')
        const btnNext = <HTMLElement>emblaContainer?.querySelector('.embla__button--next')

        if (!emblaContainer) return

        const [{ default: EmblaCarousel }] = await Promise.all([
            import('embla-carousel'),
        ])
        
        const emblaApi = EmblaCarousel(emblaContainer as HTMLElement, {
            align: 'center',
        })

        const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(emblaApi, dotsNode)
        const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(emblaApi, btnPrev, btnNext)

        onTeardown(() => {
            removeDotBtnsAndClickHandlers()
            removePrevNextBtnsClickHandlers()
            emblaApi.destroy()
        })
    },
})

