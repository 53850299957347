<template>
    <GoogleMap :markerPosition="markerPosition" :info-title="branchInfo.name" :info-desc="branchInfo.address" :info-button="branchInfo.button"></GoogleMap>

    <div class="hidden lg:block">
        <div class="absolute left-0 top-0 w-1/4 h-full bg-white overflow-y-scroll">
            <div class="h-full py-12">
                <slot name="input" :filters="filters"></slot>
                
                <div v-for="branch in dataAsync">
                    <slot name="list" :branch="branch" :onSelectBranch="onSelectBranch" :isLocationActive="isLocationActive"></slot>
                </div>
            </div>
        </div>
    </div>

    <Teleport to="main">
        <Drawer :open="isDrawerOpen">
            <DrawerTrigger as-child @click="isDrawerOpen = !isDrawerOpen">
                <div class="fixed bottom-0 left-0 w-full bg-white p-6 z-10 block lg:hidden">
                    <slot name="drawer-trigger"></slot>
                </div>
            </DrawerTrigger>
            <DrawerContent class="h-[90%] px-0" :onPointerDownOutside="() => { isDrawerOpen = !isDrawerOpen }">
                <div class="-mx-5">
                    <slot name="input" :filters="filters"></slot>

                    <ScrollArea class="h-full w-full">
                        <div v-for="branch in dataAsync">
                            <slot name="list" :branch="branch" :onSelectBranch="onSelectBranch" :isLocationActive="isLocationActive"></slot>
                        </div>
                    </ScrollArea>
                </div>
            </DrawerContent>
        </Drawer>
    </Teleport>

</template>

<script setup lang="ts">
import {
    Drawer,
    DrawerContent,
    DrawerTrigger,
} from '@/components/ui/drawer'
import { ScrollArea } from '@/components/ui/scroll-area'
import GoogleMap from './google-map.vue';
import { createBranchComputedAsync } from '../datasource'
import { computed, reactive, ref, toRef, watch } from 'vue';
import { useBreakpoints } from '@vueuse/core'
import { tailwindBreakpoints } from "../../tailwind";

const breakpoints = useBreakpoints(tailwindBreakpoints)
const lgAndGreater = breakpoints.greater('lg')

const isDrawerOpen = ref(false)

const filters = reactive({
    searchTerm: '',
})

const markerPosition = reactive({
    lat: 0, 
    lng: 0, 
})

const branchInfo = reactive({
    name: '', 
    address: '', 
    button: {
        title: '',
        link: '',
    }, 
})

const isBusy = ref(true);

const dataAsync = createBranchComputedAsync(
    toRef(filters, 'searchTerm'),
    { evaluating: isBusy }
)

watch(dataAsync, (newData) => {
  if (newData && newData.length > 0) {
    const firstBranch = newData[0]
    markerPosition.lat = firstBranch.latitude
    markerPosition.lng = firstBranch.longitude
    branchInfo.name = firstBranch.title
    branchInfo.address = firstBranch.branchAddress
    branchInfo.button.link = firstBranch.linkUrl
    branchInfo.button.title = firstBranch.linkName
  }
})

const isLocationActive = computed(() => {
    return (lat:number, lng: number): boolean => {
        if (lat === markerPosition.lat && lng === markerPosition.lng) return true
        return false
    }
})


const onSelectBranch = (lat: number, lng: number, branchName: string, branchAddress: string, buttonLink: string, buttonTitle: string) => {
    markerPosition.lat = lat
    markerPosition.lng = lng
    branchInfo.name = branchName
    branchInfo.address = branchAddress
    branchInfo.button.link = buttonLink
    branchInfo.button.title = buttonTitle

    if (lgAndGreater.value) return
    isDrawerOpen.value = false
}

</script>

<style scoped></style>