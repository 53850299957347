import { defineComponent } from 'vue'
import { BlockModule } from '../common'
import { LocateUs } from './components'

new BlockModule({
    selector: '.msig-locate-us',
    vueComponent: defineComponent({
        components: {
            LocateUs,
        }
    })
})