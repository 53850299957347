import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse, HighlightListingItem, PaginatedData } from '@/types'
import type { GenericAbortSignal } from 'axios'
import axios from 'axios'
import qs from 'qs';

export const getHighlightsListing = async (
    pageNumber: number,
    pageSize: number,
    mode?: string,
    contentTags?: string[],
    signal?: GenericAbortSignal
) => {
    
    const validContentTags = contentTags && contentTags.length > 0 && !contentTags.includes('All') ? contentTags : undefined;

    const request = axios.get<APIResponse<PaginatedData<HighlightListingItem>>>(
        `${ENDPOINTS.HIGHLIGHTS_LISTINGS_SEARCH}/search`, 
        {
            params: {
                pageNumber,
                pageSize,
                contentTags: validContentTags,
                mode,

            },
            signal,

            // When a request is cancelled, we want to suppress the toast
            suppressToast: !!signal,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }
    )
    
    const response = await useErrorHandling(request)

    return response.data.data
}
