import { unref, type MaybeRef } from 'vue';
import { computedAsync, type AsyncComputedOptions } from '@vueuse/core';
import { getHighlightsListing } from '@/api';
import type { HighlightListingItem } from '@/types';
import type { PaginatedData } from '@/types/pagination';

export const useHighlightsListingAsync = (
    pageNumber: MaybeRef<number>,
    pageSize: number,
    mode?: MaybeRef<string>,
    contentTags?: MaybeRef<string[]>,
    options?: AsyncComputedOptions
) => {
    return computedAsync<PaginatedData<HighlightListingItem>>(
        async (onCancel) => {
            const abortController = new AbortController();

            onCancel(() => abortController.abort());

            try {
                const result = await getHighlightsListing(
                    unref(pageNumber),
                    pageSize,
                    unref(mode),
                    unref(contentTags),
                    abortController.signal
                );

                return result;
            } catch (error) {
                console.error('Error fetching highlights:', error);
                return {
                    count: 0,
                    totalPages: 0,
                    items: [],
                    currentPageNumber: 1,
                    pageSize,
                };
            }
        },
        {
            count: 0,
            totalPages: 0,
            items: [],
            currentPageNumber: 1,
            pageSize,
        },
        options
    );
};
