import { computedAsync, type AsyncComputedOptions } from '@vueuse/core'
import axios from "axios";
import { unref, type MaybeRef } from 'vue'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse, BranchSearchResult } from '@/types'


export const createBranchComputedAsync = (
    searchTerm: MaybeRef<string> = '',
    options?: AsyncComputedOptions
) => {
    return computedAsync<BranchSearchResult[] | undefined>(
        onCancel => {
            const abortController = new AbortController();
        
            onCancel(() => abortController.abort());
    
            return axios.get<APIResponse<BranchSearchResult[]>>(
                `${ENDPOINTS.BRANCHES_SEARCH}`,
                {
                    signal: abortController.signal,
                    params: {
                        searchTerm: unref(searchTerm),
                    }
                }
            ).then(response => response.data.data)
        },
        undefined,
        options
    )
}