import type { EmblaCarouselType } from 'embla-carousel'

export const addDotBtnsAndClickHandlers = (
    emblaApi: EmblaCarouselType,
    dotsNode: HTMLElement,
): (() => void) => {
    let dotNodes: HTMLElement[] = []

    const addDotBtnsWithClickHandlers = (): void => {
        dotsNode.innerHTML = emblaApi
            .scrollSnapList()
            .map(
                () =>
                    `<button class="embla__dot rounded-[200px] transition-all duration-300 ease-in size-2 md:size-3 bg-gray-100" type="button" aria-label="Navigation button"></button>`,
            )
            .join('')

        const scrollTo = (index: number): void => {
            emblaApi.scrollTo(index)
        }

        dotNodes = Array.from(dotsNode.querySelectorAll('.embla__dot'))
        dotNodes.forEach((dotNode, index) => {
            dotNode.addEventListener('click', () => scrollTo(index), false)
        })
    }

    const activeClass = ['w-6', 'md:w-9', 'h-2', 'md:h-3', 'bg-primary']
    const inActiveClass = ['size-2', 'md:size-3', 'bg-white/60']

    const toggleDotBtnsActive = (): void => {
        const previous = emblaApi.previousScrollSnap()
        const selected = emblaApi.selectedScrollSnap()

        const previousDotNode = dotNodes[previous]

        if (!previousDotNode) return

        dotNodes[previous].classList.remove('embla__dot--selected')
        dotNodes[previous].classList.remove(...activeClass)
        dotNodes[previous].classList.add(...inActiveClass)
        dotNodes[selected].classList.remove(...inActiveClass)
        dotNodes[selected].classList.add('embla__dot--selected')
        dotNodes[selected].classList.add(...activeClass)
    }

    emblaApi
        .on('init', addDotBtnsWithClickHandlers)
        .on('reInit', addDotBtnsWithClickHandlers)
        .on('init', toggleDotBtnsActive)
        .on('reInit', toggleDotBtnsActive)
        .on('select', toggleDotBtnsActive)

    return (): void => {
        dotsNode.innerHTML = ''
    }
}
