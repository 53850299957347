import { defineComponent, defineAsyncComponent, ref, reactive, toRef, watch } from 'vue';
import { BlockModule } from '../common';
import { useHighlightsListingAsync } from '@/datasources/highlights-listings';
import { RadioGroupRoot, RadioGroupItem, RadioGroupIndicator } from 'radix-vue';
import { DropdownMenuTrigger, DropdownMenu, DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu';

new BlockModule({
    selector: '.block-highlights-listing',
    vueComponent: defineComponent({
        components: {
            DropdownMenuTrigger,
            DropdownMenu,
            DropdownMenuItem,
            DropdownMenuContent,
            RadioGroupRoot,
            RadioGroupItem,
            RadioGroupIndicator,
            ToggleGroupRoot: defineAsyncComponent(() => import('radix-vue').then((x) => x.ToggleGroupRoot)),
            ToggleGroupItem: defineAsyncComponent(() => import('radix-vue').then((x) => x.ToggleGroupItem)),
            LazyImage: defineAsyncComponent(() => import('@/components/website/lazy-image/lazy-image.vue')),
            AutocompleteControl: defineAsyncComponent(() => import('@/components/website/forms/autocomplete-control/AutocompleteControl.vue')),
            Pagination: defineAsyncComponent(
                () => import('@/components/website/pagination/Pagination.vue')
            )
        },
        props: {
            defaultSort: { type: String, default: 'AtoZ' },
            pageSize: { type: Number, default: 24 },
        },
        setup(props) {
            const isBusy = ref(true);

            const filters = reactive({
                groupKey: 'All',
                contentTags: ['All'] as string[],
                pageNumber: 1,
                mode: props.defaultSort,
            });

            const highlightListingAsync = useHighlightsListingAsync(
                toRef(filters, 'pageNumber'),
                props.pageSize,
                toRef(filters, 'mode'),
                toRef(filters, 'contentTags'),
                { evaluating: isBusy }
            );

            const updateCategory = (groupKey: string) => {
                if (groupKey === 'All') {
                    // If "All" is selected and there are no other content tags, reset to an empty array
                    if (filters.contentTags.length === 0 || filters.contentTags.includes('All')) {
                        filters.contentTags = [];
                        filters.groupKey = 'All';
                    } else {
                        // If other tags are selected, deselect "All"
                        filters.contentTags = filters.contentTags.filter(tag => tag !== 'All');
                        filters.groupKey = filters.contentTags.length > 0 ? '' : 'All';
                    }
                } else {
                    // If a specific category is selected, toggle it and manage "All"
                    if (filters.contentTags.includes(groupKey)) {
                        filters.contentTags = filters.contentTags.filter(tag => tag !== groupKey);
                    } else {
                        filters.contentTags.push(groupKey);
                    }
            
                    // If any tag is selected, ensure "All" is deselected
                    filters.contentTags = filters.contentTags.filter(tag => tag !== 'All');
            
                    // If no tags remain selected, default to "All"
                    if (filters.contentTags.length === 0) {
                        filters.groupKey = 'All';
                    } else {
                        filters.groupKey = '';
                    }
                }
            
                filters.pageNumber = 1;
            };
            

            const toggleCategory = (key: string) => {
                if (key === 'All') {
                    filters.contentTags = [];
                } else {
                    filters.contentTags = filters.contentTags.includes(key)
                        ? filters.contentTags.filter((tag) => tag !== key)
                        : [...filters.contentTags, key];
                    filters.contentTags = filters.contentTags.filter((tag) => tag !== 'All');
                }
                filters.pageNumber = 1;
            };

            const handleAutocompleteChange = (selectedTags: string[]) => {
                if (selectedTags.includes('All')) {
                    filters.contentTags = [];
                    filters.groupKey = 'All';
                } 
                else {
                    filters.contentTags = selectedTags;
                    filters.groupKey = '';
                }
            };

            watch(
                () => filters.contentTags,
                (newTags) => {
                    if (newTags.length === 0) {
                        filters.contentTags = ['All'];
                        filters.groupKey = 'All';
                    }
                },
            );

            return {
                isBusy,
                highlightListingAsync,
                updateCategory,
                handleAutocompleteChange,
                filters,
                toggleCategory,
            };
        },
    }),
});
