<template>
    <div class="aspect-[450/750] md:aspect-[1440/650]">
        <div id="google-map"></div>
    </div>
</template>

<script setup lang="ts">
import { Loader } from '@googlemaps/js-api-loader';
import { ref, toRef, toRefs, watch } from 'vue';

const props = defineProps({
    markerPosition: {
        type: Object,
        required: true
    },
    infoTitle: {
        type: String,
    },
    infoDesc: {
        type: String,
    },
    infoButton: {
        type: Object,
    },
})

const markerPosition = toRef(props, 'markerPosition')
const title = toRef(props, 'infoTitle')
const desc = toRef(props, 'infoDesc')
const button = toRef(props, 'infoButton')

const loader = new Loader({
    apiKey: "AIzaSyC6TJivkLHmisrvJCi-KXsKFAethJBgdms",
    version: "weekly",
    libraries: ["places"]
});

const mapOptions = {
    center: {
        lat: markerPosition.value.lat,
        lng: markerPosition.value.lng,
    },
    zoom: 17,
    mapId: 'DEMO_MAP_ID',
};

let customGoogleMap: google.maps.Map;
let customMarker: google.maps.marker.AdvancedMarkerElement
let infowindow: google.maps.InfoWindow
let contentString: string

const initGoogleMap = async () => {
    
    const { Map } = await loader.importLibrary('maps');
    const { AdvancedMarkerElement, Marker } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    customGoogleMap = new Map(document.getElementById("google-map") as HTMLElement, mapOptions);
    
    customMarker = new AdvancedMarkerElement({
        map: customGoogleMap,
        position: markerPosition.value as google.maps.LatLngLiteral,
    });

    loadInfoWindow();
}

const populateContentString = (title: string | undefined, desc: string | undefined, buttonTitle: string, buttonLink?: string) => {
    contentString = `
    <div class="space-y-2">
        <p class="heading-7">${title}</p>
        <p class="body-2">${desc}</p>
        <a href="${buttonLink}" target="_blank" class="text-secondary flex items-center gap-1 font-medium">${buttonTitle}
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.63215 12.9986H3.03529C2.43308 12.9986 1.92226 12.8064 1.55906 12.4432C1.19586 12.08 1.00371 11.5669 1.00371 10.9623C1.00137 8.35664 1.00137 6.00404 1.00371 3.76859C1.00371 3.16638 1.1982 2.65556 1.56374 2.2947C1.92929 1.93384 2.44011 1.7417 3.04466 1.7417H5.43476C5.72766 1.7417 5.96667 1.8284 6.12836 1.99242C6.27364 2.13771 6.34862 2.33922 6.34628 2.57355C6.34159 3.07031 5.9901 3.38899 5.44648 3.39133H3.8109H3.05404C2.72598 3.39133 2.64866 3.46866 2.64866 3.8014V10.9436C2.64866 11.274 2.72598 11.3489 3.05638 11.3489H6.62746C6.62746 11.3489 9.00819 11.3489 10.1985 11.3489C10.5289 11.3489 10.6063 11.274 10.6063 10.9412C10.6063 10.5499 10.6063 10.1586 10.6063 9.76726C10.6063 9.36189 10.6063 8.95651 10.6063 8.55113C10.6063 8.0075 10.9296 7.65367 11.4264 7.65133H11.4311C11.9302 7.65133 12.2536 8.00047 12.2536 8.54175V8.7667C12.2559 9.50014 12.2559 10.2593 12.2536 11.0045C12.2512 11.5833 12.0567 12.08 11.6912 12.4409C11.3256 12.8017 10.8289 12.9939 10.2478 12.9962C9.77442 12.9962 9.30109 12.9962 8.8301 12.9962H6.63215V12.9986Z" fill="#E11F26"/>
                <path d="M6.20018 8.56517C5.92134 8.56517 5.64718 8.38005 5.5183 8.1059C5.35896 7.76613 5.42692 7.39824 5.70107 7.1194C6.04318 6.7726 6.39467 6.42346 6.73444 6.08603L7.0953 5.72752L8.25988 4.56996C8.88552 3.949 9.50882 3.32805 10.1321 2.70475C10.1438 2.69303 10.1556 2.68132 10.1673 2.66726C9.78767 2.66023 8.96519 2.65554 8.50826 2.6532C8.24816 2.6532 8.0279 2.56884 7.87325 2.4095C7.72562 2.25954 7.6483 2.05333 7.65064 1.81666C7.65533 1.33162 7.99978 1.00591 8.50592 1.00356C9.12922 1.00122 9.74783 1.00122 10.3688 1.00122C10.9991 1.00122 11.5826 1.00122 12.1543 1.00356C12.6628 1.00356 12.9932 1.32927 12.9955 1.83072C13.0002 3.08435 13.0002 4.32626 12.9955 5.526C12.9932 6.0087 12.6558 6.34847 12.1754 6.34847C11.6951 6.34847 11.3576 6.01105 11.3529 5.53068C11.3506 5.28465 11.3483 4.9027 11.3459 4.53247C11.3436 4.26065 11.3436 4.00056 11.3412 3.82716C11.3225 3.84356 11.3061 3.85996 11.2897 3.87636C10.9288 4.23956 10.568 4.60042 10.2071 4.96362C9.14328 6.03214 8.0443 7.13814 6.95704 8.21837C6.78833 8.3824 6.57744 8.49721 6.35015 8.55111C6.2986 8.56048 6.24939 8.56517 6.20018 8.56517Z" fill="#E11F26"/>
            </svg>
        </a>
    </div>
    `
}


const loadInfoWindow = () => {
    populateContentString(title.value, desc.value, button.value?.title, button.value?.link)

    infowindow = new google.maps.InfoWindow({
        content: contentString,
    });

    infowindow.open({
        anchor: customMarker,
        map: customGoogleMap,
    })
}

watch(() => markerPosition.value, (newValue) => {
    customMarker.position = {lat: newValue.lat,  lng: newValue.lng}
    customGoogleMap.setCenter({lat: newValue.lat,  lng: newValue.lng})
    populateContentString(title.value, desc.value, button.value?.title)
    infowindow.setContent(contentString)

}, { deep: true })

initGoogleMap()

</script>

<style>
#google-map {
    @apply w-full h-full
}

.gm-style-iw-tc, .gm-style-iw-chr {
    display: none;
}

.gm-style .gm-style-iw-c {
    @apply shadow-none border-2 border-primary !p-4 !max-w-80 lg:!max-w-sm rounded-2xl font-sans
}

.gm-style-iw-d {
    @apply overflow-auto
}
</style>