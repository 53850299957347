import { unref, type MaybeRef } from 'vue'
import { computedAsync, type AsyncComputedOptions } from '@vueuse/core'
import { getProductListing } from '@/api'
import type { ProductListingItem } from '@/types'

export const useProductListingAsync = (
    groupKey: MaybeRef<string>,
    categoryTags?: MaybeRef<string[]>,
    classificationTags?: MaybeRef<string[]>,
    options?: AsyncComputedOptions
) => 
    computedAsync<ProductListingItem[]>(
        (onCancel) => {
            const abortController = new AbortController()

            onCancel(() => abortController.abort())

            return getProductListing(
                unref(groupKey),
                unref(categoryTags),
                unref(classificationTags),
                abortController.signal
            )
        },
        [],
        options,
    )