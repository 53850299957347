import errorMessages from '../error-messages'
import axios from 'axios'

interface ErrorMessageReturn {
    title: string | null
    description: string
}

export function getErrorMessage(error: any): ErrorMessageReturn {
    let errorMessage = errorMessages.UNEXPECTED_ERROR
    let title: string | null = null

    if (typeof error === 'string') {
        title = errorMessages.EXPECTED_ERROR
        errorMessage = error
    }

    if (axios.isAxiosError(error) && error.response) {
        const response = error.response
        if (Array.isArray(response?.data.messages)) {
            errorMessage = response.data.messages[0] || errorMessage
        }
        if (response.status === 401) {
            title = 'Unauthorized'
            errorMessage = errorMessages.AUTH_NOT_PRESENT
        }
    }

    return {
        title,
        description: errorMessage,
    }
}
