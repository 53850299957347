import { defineComponent, defineAsyncComponent, ref, watchEffect, computed, watch } from 'vue'
import { BlockModule } from '../common'
import { useBreakpoints, useScroll } from '@vueuse/core';
import { tailwindBreakpoints } from '../tailwind';
import { useNavContext } from './composables';

interface NavKeyItem {
    key: string
    keys: NavKeyItem[]
}

new BlockModule({
    selector: '.msig-global-announcement-and-main-nav',
    vueComponent: defineComponent({
        components: {
            ColorModeSwitch: defineAsyncComponent(
                () => import('./components/ColorModeSwitch.vue')
            ),
            RadixNavigationMenuRoot: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuRoot)
            ),
            RadixNavigationMenuSub: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuSub)
            ),
            RadixNavigationMenuList: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuList)
            ),
            RadixNavigationMenuItem: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuItem)
            ),
            RadixNavigationMenuLink: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuLink)
            ),
            RadixNavigationMenuTrigger: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuTrigger)
            ),
            RadixNavigationMenuContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuContent)
            ),
            RadixNavigationMenuViewport: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuViewport)
            ),
            DialogTitle: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogTitle)
            ),
            DialogDescription: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogDescription)
            ),
            DialogContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogContent)
            ),
            DialogPortal: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogPortal)
            ),
            DialogRoot: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogRoot)
            ),
            DialogTrigger: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogTrigger)
            ),
            Tabs: defineAsyncComponent(
                () => import('@/components/website/tabs/Tabs.vue')
            ),
            TabsTrigger: defineAsyncComponent(
                () => import('@/components/website/tabs/TabsTrigger.vue')
            ),
            TabsContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.TabsContent)
            ),
            LazyImage: defineAsyncComponent(
                () => import('@/components/website/lazy-image/lazy-image.vue')
            )
        },
        props: {
            navKeys: { type: String }
        },
        setup(props) {
            const { 
                globalAnnouncementAndMainNavRef,
                isNavHidden,
                isMobileNavOpen,
                isGlobalSearchOpen,
                desktopFirstLevelActiveKey,
                desktopSecondLevelActiveKey,
                desktopThirdLevelActiveKey,
                mobileFirstLevelActiveKey,
                mobileSecondLevelActiveKey,
                mobileIntermediariesActiveKey,
                closeDesktopNav,
                closeMobileNavDialog,
                hideDesktopSecondLevelNav,
                hideDesktopThirdLevelNav
            } = useNavContext()

            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const xlAndGreater = breakpoints.greaterOrEqual('xl')

            const navKeys = props.navKeys ? JSON.parse(props.navKeys) as NavKeyItem[] : []

            const defaultDesktopSecondLevelActiveKey = computed(() => {
                const secondLevelKeys = navKeys.find(x => x.key === desktopFirstLevelActiveKey.value)?.keys
                if (secondLevelKeys && secondLevelKeys.length > 0) {
                    return secondLevelKeys[0].key
                }
            })

            const defaultDesktopThirdLevelActiveKey = computed(() => {
                const thirdLevelKeys = navKeys.find(x => x.key === desktopFirstLevelActiveKey.value)?.keys.find(y => y.key === desktopSecondLevelActiveKey.value)?.keys
                if (thirdLevelKeys && thirdLevelKeys.length > 0) {
                    return thirdLevelKeys[0].key
                }
            })


            return {
                globalAnnouncementAndMainNavRef,
                isNavHidden,
                xlAndGreater,
                isMobileNavOpen,
                isGlobalSearchOpen,
                desktopFirstLevelActiveKey,
                desktopSecondLevelActiveKey,
                desktopThirdLevelActiveKey,
                mobileFirstLevelActiveKey,
                mobileSecondLevelActiveKey,
                mobileIntermediariesActiveKey,
                closeDesktopNav,
                closeMobileNavDialog,
                hideDesktopSecondLevelNav,
                hideDesktopThirdLevelNav
            }
        },
    }),
    async setupBlock(el, onTeardown) {
        let totalHeight = 0
        const navbarElHeight = (el as HTMLElement)?.offsetHeight
        const globalAnnouncementBarEl = (el.querySelector('.msig-global-announcement-bar') as HTMLElement)?.offsetHeight

        totalHeight += navbarElHeight || 0
        totalHeight += globalAnnouncementBarEl || 0

        document.documentElement.style.setProperty('--page-offset-top', `${totalHeight || 0}px`)

        const emblaContainer = el.querySelector('.embla') as HTMLElement
        if (!emblaContainer) return

        const [{ default: EmblaCarousel }, { default: EmblaCarouselAutoplay }] = await Promise.all([
            import('embla-carousel'),
            import('embla-carousel-autoplay')
        ])

        const emblaApi = EmblaCarousel(emblaContainer, { loop: true }, [
            EmblaCarouselAutoplay({
                active: true,
                delay: 5000,
                stopOnMouseEnter: true
            })
        ])

        onTeardown(() => {
            emblaApi.destroy()
        })
    }
})